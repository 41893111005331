@import "../../../styles/common";

.retrain-classifier-popover-form {

  font-size: $font-size-base;

  &__footer {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }

  &__cancel {
    margin-right: 15px;
  }

  &__submit {
    min-width: 160px;
  }

  :global(.custom-checkbox) {
    margin-bottom: 1rem;
  }

}