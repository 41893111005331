@use "../../../../../styles/common";

$action-border: 1px solid common.$ct-gray-200;
$side-padding: 16px;
$user-spacing: 16px;
$outgoing-bg: common.$ct-blue-13;
$incoming-bg: common.$ct-gray-175;

.messageBasic {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    $self: &;

    &_out {
        flex-direction: row-reverse;
        #{$self}__content {
            background-color: $outgoing-bg;
            border-radius: 5px 0 5px 5px;
        }
        #{$self}__actions {
            border-radius: 5px 0 5px 5px;
        }
        #{$self}__user {
            margin-right: 0;
            margin-left: $user-spacing;
        }
    }

    &__body {
        overflow: hidden;
        word-wrap: break-word;
        word-break: break-word;
    }

    &__user {
        margin-right: $user-spacing;
        margin-left: 0;
    }

    &__content {
        padding: $side-padding;
        background-color: $incoming-bg;
        border-radius: 0 5px 5px 5px;
    }

    &__text {
    }

    &__section {
        margin-top: 8px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__actions {
    }

    &__action {
        width: 100%;
        padding: 10px $side-padding;
        color: common.$primary;
        background-color: common.$white;
        margin-bottom: 9px;
        text-align: center;
    }

    &__attachments {
        display: grid;
        grid-template-columns: repeat(auto-fit, 160px);
        grid-auto-rows: 160px;
        gap: 16px;
    }

    &__attachment {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__footer {
        display: flex;
        color: common.$ct-gray-650;
        font-size: common.$font-size-base * 0.86;
        padding-top: 6px;
        align-items: center;
        justify-content: flex-start;
    }

    &__info {
        margin-right: 6px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__icon {
        margin-top: 4px;

        &_operator {
            color: common.$primary;
            font-size: 16px;
        }
    }

    &__markdownContainer div {
        background: none;

        :global(.heading-actions) {
            display: none;
        }
    }
}
