@use "../../../styles/common";

$message-input-vertical-spacing: 20px;
$advanced-input-height: 3.5rem;

.messageInput {
  padding: 12px 15px 15px 15px;

  &__user {
    margin-bottom: $message-input-vertical-spacing;
  }
  &__wrapper {
    flex-grow: 1;
  }
  &__footer {
    display: flex;
    margin-top: 5px;
  }
  &__quickButton {
    cursor: pointer;
  }
  &__quickButtons {
    display: flex;
    flex: 1;
    gap: 12px;
    align-items: flex-end;
    color: common.$primary;
    font-size: 18px;
  }
  &__fileInput {
    display: none;
  }
  &__control {
    flex-grow: 1 !important;
    height: 100% !important;
    border: none;
    resize: none;
    padding: 5px 0;
    overflow: auto;
    &_advanced {
      & > div:first-child {
        height: 100%;
      }
    }
    &_disabled {
      & > div:first-child {
        &:before {
          content: "Read only mode";
          color: #d0d0d0;
          font-size: 1.25rem;
        }
      }
    }
    &_stable {
      max-height: 265px;
      justify-content: flex-start!important;
    }
    :global(.ProseMirror) {
      word-break: break-all;
    }
  }
  &__buttonGroup {
    height: 100%;
    display: flex;
    align-items: flex-end;
    > button {
      padding: 0.4rem !important;
    }
  }
  &__submit {
    margin-left: 5px;
  }
}