@use "../../styles/common" as common;

$keyboard-button-text-color: common.$ct-blue;
$keyboard-button-popover-width: 100%;
$keyboard-button-settings-buttons-width: 50px;
$keyboard-button-settings-button-size: 0.9 * common.$ct-font-size-base;

.contentButton {
  flex: 1;
  font-size: common.$ct-font-size-base;
  $self: &;
  height: 36px;
  &__btn {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: $keyboard-button-settings-buttons-width;
    position: relative;
    @include common.card-border;
    border-radius: 5px;
  }
  &__btn:hover {
    background-color:  common.$body-bg;
  }

   &__textarea {
    @include common.mini-scrollbar(2px);
    @include common.no-outline-focus;
    text-align: center;
    flex-basis: calc(100% - #{$keyboard-button-settings-buttons-width});
    border: none;
    resize: none;
    color: $keyboard-button-text-color;
    background-color: inherit;
    padding: 2px 0;
  }

  &__dragIcon {
    @include common.transition();
    cursor: grab;
    color: common.$knowledge-base-sub-text;
    font-size: 1rem;
    z-index: 1;
    position: absolute;
    left: 12px;
  }

  &__settingsButtons {
    padding-right: 12px;
    display: flex;
  }

  &__settingsBtn {
    @include common.transition();
    padding: 0;
    color: common.$knowledge-base-sub-text;
    font-size: $keyboard-button-settings-button-size;
    opacity: 0;
    pointer-events: none;
    margin-left: 10px;
  }

  &__popover {
    font-size: common.$knowledge-base-form-font-size;
    :global(.arrow) {
      display: none;
    }
  }

  &:not(.contentButton_disabled) {
    &:hover {
      #{$self}__settingsBtn {
        opacity: 1;
        pointer-events: auto;
      }
      #{$self}__dragIcon {
        color: $keyboard-button-text-color;
      }
    }
  }

  &_disabled {
    #{$self}__content {
      pointer-events: none;
    }
    #{$self}__settingsButtons {
      opacity: 0;
    }
    #{$self}__textarea{
      color: common.$ct-gray-600;
    }
  }
}