@use "../../../styles/common" as common;

.surveyForm {
  @include common.page-layout-sidebar;
  @include common.sidebar-form;

  &__field {
    margin-bottom: 16px;
  }

  &__increasedFieldSize {
    white-space: break-spaces;
  }
}