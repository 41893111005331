@import "../../styles/common";

$knowledge-base-permission-project-color: $knowledge-base-sub-text;
$knowledge-base-permission-card-font-size: $ct-font-size-base;

.knowledge-base-permission {
  display: flex;

  &__card {
    flex: 1;
    .user-card-introduction {
      font-size: $knowledge-base-permission-card-font-size;
    }
  }

  &__project {
    color: $knowledge-base-permission-project-color;
  }

  &__option {
    .menu-item-row__title {
      @include text-truncate();
      width: auto;
    }
  }

  &__remove {
    display: flex;
    align-items: center;
  }
}
