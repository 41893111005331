.initialButtonSettings {
  width: 100%;
  &__articleSelect {
    padding: 12px 8px 0 8px;
  }
  &__buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    padding: 0 8px 20px 0px;
  }
  &__button {
    margin-left: 16px;
  }
}
