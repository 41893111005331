.galleryPreviewer {
    &__container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &__toolbar {
        height: 102px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        flex-shrink: 0;
    }
    &__title {
        font-size: 24px;
        margin: 0;
    }
    &__manage {
        display: flex;
        gap: 20px;
    }
    &__manageButton {
        border: 0 none;
        background: transparent;
    }
    &__content {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        gap: 50px;
    }
    &__contentButton {
        border: 0 none;
        background: rgba(255,255,255,.5);
        width: 60px;
        height: 60px;
        border-radius: 999px;
        color: #fff;
        transition: all .3s ease;
        flex-shrink: 0;
        margin: 0 30px;

        &:hover {
            background: rgba(255,255,255,.6);
        }
    }
    &__iconWrapper {
        width: 760px;
        height: 490px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__imageWrapper {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__attachmentImage {
        max-height: 75vh;
        max-width: 760px;
    }
    @media (max-width: 1096px) {
        &__attachmentImage {
            max-width: 560px;
        }
    }
    @media (max-width: 860px) {
        &__attachmentImage {
            max-width: 260px;
        }
    }
}
