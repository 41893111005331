@use "../../../styles/common";

$project-picture-size: 20px;
$picture-gap: 4px;

.dialogPath {
    display: flex;
    flex-direction: row;
    font-size: common.$font-size-sm;
    align-items: center;
    color: common.$ct-gray-600;
    &__item {
        @include common.no-wrap-text();
        display: flex;
        flex-direction: row;
        align-items: center;
        //max-width: 30%;
        color: rgb(162, 162, 162);
    }
    &__defaultPicture {
        font-size: $project-picture-size;
        margin-right: $picture-gap;
    }
    &__picture {
        border-radius: 3px;
        border: 1px solid common.$ct-gray-500;
        margin-right: $picture-gap;
        width: $project-picture-size;
        height: $project-picture-size;
    }
    &__channel {
        &_item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;
        }
        &_title {
            font-size: 0.9rem;
        }
        &_picture {
            font-size: $project-picture-size;
            margin-right: $picture-gap;
        }
    }
    &__projectTitle {
    }
    &__pointer {
        margin: 0 10px;
        font-size: $project-picture-size;
    }
}
