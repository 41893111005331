@use "../../../styles/common";

$header-height: 70px;
$footer-height: 150px;
$x-spacing: 10px;
$y-spacing: 20px;
$action-size: 22px;

.dialogChat {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100vh;
    &__header {
        position: sticky;
        top: 0;
        width: 100%;
        height: $header-height;
        border-bottom: 1px solid common.$ct-gray-300;
        display: flex;
        align-items: center;
        padding: 0 14px;
        justify-content: space-between;
        flex: 0 0 $header-height;
        background-color: #fff;
    }
    &__action {
        font-size: $action-size;
    }
    &__body {
        display: flex;
        overflow-x: hidden;
        overflow-y: auto;
        padding: $y-spacing $x-spacing;
        flex: 1;
        flex-direction: column-reverse;
    }
    &__footer {
        padding: $y-spacing $x-spacing;
        flex: 0 0 $footer-height;
    }
    &__window {
        border: 1px solid common.$border-color;
        border-radius: common.$border-radius-lg;
        display: flex;
        flex-direction: column;
    }
    &__inputHeader {
        height: 50px;
        border-bottom: 1px solid common.$border-color;
        padding: 15px;
    }
    &__attachments {
        display: flex;
        align-items: center;
        margin: 0 10px 20px;
        flex-wrap: wrap;
    }
    &__attachment {
        margin: 7px;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    &__moreFiles {
        font-size: 14px;
        color: #a2a2a2;
        width: 160px;
        text-align: center;
        cursor: pointer;
    }
    &__loadMore {
        font-size: 0.875rem;
        color: #1066a8;
        background: none;
        border: none;
        margin-bottom: 20px;
    }
}
