@use "../../styles/common";

$user-card-introduction-font-size: common.$ct-font-size-base * 1.125;
$user-card-picture-size: 40px;
$user-data-max-width: 120px;

@mixin user-picture-size {
    height: $user-card-picture-size;
    width: $user-card-picture-size;
    border-radius: 50%;
}

@mixin user-introduction-properties {
    font-size: $user-card-introduction-font-size;
}

.userCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    $self: &;
    &_short {
        align-items: initial;
        #{$self}__picture {
            margin-right: 0;
        }
    }
    &__picture {
        @include user-picture-size;
        margin-right: 10px;
        &_default {
            user-select: none;
            background-color: #f2c94c;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: common.$white;
        }
    }
    &__pictureContainer {
        position: relative;
    }
    &__indicator {
        position: absolute;
        bottom: 0;
        left: -3px;
    }
    &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    &__introduction {
        user-select: none;
        @include user-introduction-properties;
        display: flex;
        flex-direction: row;
    }
    &__firstName {
        @include common.no-wrap-text();
        max-width: $user-data-max-width;
        margin-right: 5px;
    }
    &__lastName {
        @include common.no-wrap-text();
        max-width: $user-data-max-width;
        flex: 1 1;
    }
    &__clientFullName {
        @include common.no-wrap-text();
        font-size: 1rem;
    }
}
