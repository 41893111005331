.galleryBox {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__wrapper {
        background: #fff;
        border-radius: 8px;
        max-width: 720px;
        min-width: 380px;
        min-height: 480px;
        display: grid;
        grid-template-columns: repeat(auto-fit, 160px);
        grid-auto-rows: 160px;
        gap: 16px;
        padding: 20px;
    }

    &__attachment {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}