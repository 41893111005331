@use "../../styles/common";

$menu-user-min-width: 200px;

.menuItemUser {
    &__toggle {
        @include common.disable-default-button-styles;
    }
    &__context {
        min-width: $menu-user-min-width;
    }
    &__card {
        justify-content: center;
        :global(.user-card-introduction) {
            font: {
                size: 16px;
            };
        }
    }
}