@use "../../styles/common";

.attachmentItem {
    background: #fff;
    position: relative;

    &__container {
        display: flex;
        flex-direction: column;
        padding: 8px;
        border-radius: 5px;
        border: 1px solid #e0e0e0;
        width: 160px;
        height: 160px;
    }

    &__link {
        color: inherit;
        text-decoration: none;
        cursor: pointer;

        &:hover,
        &:visited {
            color: inherit;
            text-decoration: none;
        }
    }

    &__headerContainer {
        display: flex;
        align-items: center;
        border-radius: 5px;
        margin-bottom: 6px;
    }

    &__headerTextContainer {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
    }

    &__headerTextTitle {
        color: #1066a8;
        font-size: 14px;
        line-height: normal;
    }

    &__headerTextSub {
        font-size: 12px;
        color: #a2a2a2;
        line-height: normal;
    }

    &__previewContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 5px;
        flex: 1 1 0%;
        overflow: auto;
        border: 1px solid #e0e0e0;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
    }

    &__icon {
        cursor: pointer;
    }

    &__closeButton {
        border: none;
        padding: 0;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #333333;
        position: absolute;
        top: -8px;
        right: -8px;
    }

    &__closeIcon {
        path {
            fill: #fff;
        }
    }
}
