@use "../../styles/common";

.dialogSystem {
    &__mainContainer {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.65);
        display: none;
        visibility: hidden;

        &_shown {
            display: block;
            visibility: visible;
        }
    }
}