
@mixin sub-small-title {
    font: {
        size: $font-size-base;
        weight: 500;
    }
    user-select: none;
    color: $gray-500;
}

@mixin fit-image {
    img {
        height: 100%;
        width: 100%;
    }
}

@mixin loading-element {
    background: $loading-background;
    background-size: 300% 300%;
    animation: ElementOnLoading 2s ease infinite;
}

@mixin loading-with-spinner {
    animation: LoadingWithSpinner 1s ease-in-out infinite;
}

@mixin no-wrap-text($overflow: "ellipsis") {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: #{$overflow};
}

@mixin no-outline-focus {
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

@mixin no-outline-focus-within {
    &:focus-within {
        outline: none;
        box-shadow: none;
    }
}

@mixin no-underline-focus {
    &:focus {
        text-decoration: none;
    }
}

@mixin public-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__container {
        width: $public-form-default-width;
    }
    h1 {
        font: {
            size: $public-form-h1-size;
            weight: $public-form-h1-weight;
        }
        margin-bottom: 60px;
    }
    h2 {
        font: {
            size: $public-form-h2-size;
            weight: $public-form-h2-weight;
        };
        margin-bottom: 40px;
    }
    &__submit {
        margin-top: 15px;
        width: 100%;
    }
    &__footer {
        padding-top: 1.5rem;
        text-align: center;
    }
}

@mixin section-border {
    border-bottom: 1px solid $ct-gray-450;
}

@mixin form-section-padding {
    padding-top: 11px;
}

@mixin card-border {
    border: 1px solid $ct-gray-300;
}

@mixin page {
    padding: 40px 40px 25px 40px;
    &__header {
        margin-bottom: 40px;
    }
    &__title {
        font-size: $page-title-font-size;
        user-select: none;
    }
    &__body {
        padding: 0 15px;
    }
}

@mixin tab-page {
    &__header {
        margin-bottom: 40px;
    }
    &__title {
        font-size: $page-title-font-size;
        user-select: none;
    }
    &__btn-add {
        @include transition-all;
        float: right;

        &_hidden {
            opacity: 0;
            pointer-events: none;
        }
    }
}

@mixin page-layout-sidebar() {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;

    &__content {
        padding: 0 15px;
        overflow: auto;
        flex-grow: 1;
        min-height: 0;
    }
    &__footer {
        padding: 15px 15px 0.2rem 15px;
    }
}

@mixin enable-focus-within {
    &:focus-within {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        border-color: $input-focus-border-color;
        outline: 0;
        @if $enable-shadows {
            box-shadow: $input-box-shadow, $input-focus-box-shadow;
        } @else {
            box-shadow: $input-focus-box-shadow;
        }
    }
    &.is-invalid:focus-within {
        border-color: $danger;
        box-shadow: 0 0 0 $input-focus-width rgba($danger, .25);
        width: 100%;
    }
}

@mixin interactive-menu-item {
    cursor: pointer;
    padding: 5px;
    border-radius: $border-radius;
    &:hover {
        background-color: $sidebar-hover-color;
    }
    &_selected {
        color: $sidebar-selected-color;
    }
}

@mixin img-size($value) {
    width: $value;
    height: $value;
}

@mixin text-muted {
    font: {
        size: $ct-font-size-base * 0.875;
        weight: 400;
    }
    color: $text-muted;
}

@mixin flex-start-row {
    display: flex;
    justify-content: flex-start;
}

@mixin flex-center-row {
    display: flex;
    justify-content: center;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin button-icon {
    padding: 8px 14px;
    color: $ct-gray-650;
    cursor: pointer;

    &:hover {
        color: $ct-black-50;
    }

    &__icon {
        color: inherit;
    }
}

@mixin sidebar-form {
    &__content {
        padding: 0 22px;
    }

    &__section {
        @include form-section-padding;

        &_border {
            @include section-border;
        }

        &_indent {
            margin-top: 0.5rem;
        }
    }

    &__section-subtitle {
        display: block;
        margin-bottom: 15px;
        font-size: $font-size-base * 1.14;

        + .radio-input {
            margin-top: -0.5rem;
        }
    }

    &__subfield {
        @include subfield;
    }

    &__text {
        margin-bottom: 1rem;

        + .radio-input, + .checkbox-input {
            margin-top: -5px;
        }
    }

    .extra-settings {
        padding-left: 25px;
        margin: 0.5rem 0;
    }

    .form-accordion {
        &__header {
            font-size: $ct-font-size-base * 1.15;
        }

        &__body {
            &:first-child {
                margin-top: 8px;
            }

            &:last-child {
                margin-bottom: 24px;
            }
        }
    }
}

@mixin mini-scrollbar($width: 4px) {
    &::-webkit-scrollbar {
        width: $width;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: none;
        background-color: $ct-gray-300;
    }
}

@mixin article-action {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    @include no-outline-focus();
    @include no-underline-focus();
}

@mixin article-section {
    padding: 0 40px;
    max-width: 800px;
    margin: 0 auto 35px;
}

@mixin article-section-expanded {
    padding: 0 20px;
    max-width: none;
}

@mixin article-view {
    height: auto;
    min-width: 500px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 40px 40px 5px;
        flex-wrap: wrap;

        background-color: $body-bg;
        z-index: 201;
        position: sticky;
        top: 0;
        left: $article-view-left-indent;
        right: 0;
    }
}

@mixin knowledge-base-popover {
    width: $knowledge-base-popover-width;
    max-width: $knowledge-base-popover-width;

    .popover-body {
        padding: 20px;
    }
}

@mixin scenario-handle {
    right: -($scenario-node-handle-size / 2);
    background-color: $scenario-node-controls-color;
    height: $scenario-node-handle-size;
    width: $scenario-node-handle-size;

    &.connectable {
        cursor: pointer;
    }
}

@mixin set-operator-status-color($color) {
    background-color: $color;
    &:hover {
        background-color: darken($color, 5%);
    }
}

@mixin knowledge-base-create-edit-form-popover {
    &__content {
        padding-bottom: 14px;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
    }

    &__cancel {
        margin-right: 15px;
    }

    &__submit {

    }

    :global(.form-label) {
        font-size: $knowledge-base-form-font-size;
    }
}

@mixin knowledge-form-popover {
    padding: 8px;

    &__content {
        padding-bottom: 14px;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
    }

    &__cancel {
        margin-right: 16px;
    }

    &__title {
        font-size: 16px;
        margin-bottom: 0.5rem;
    }

    &__subtitle {
        padding-top: 0.5rem;
        font-size: 15px;
    }

    &__subsection {
        padding-left: 1.5rem;
    }

    &__inline {
        display: flex;
        margin-top: 0.5rem;
        flex-wrap: wrap;

        >* {
            margin-right: 20px;
        }
    }

    &__checkbox {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

@mixin subfield {
    padding-left: 25px;
}

@mixin invalid-control {
    border-color: $danger;
    box-shadow: 0 0 0 $input-focus-width rgba($danger, .25);
}

@mixin closeIconControl {
    @include no-outline-focus;
    font-size: 2.1 * $font-size-base;
    font-weight: 400;
}

@mixin selectedTag {
    background-color: $ct-gray-300;
    color: $ct-black-50;
    margin-right: 10px;
    border: none;

    .tag__icon, svg {
        color: inherit;
    }

    svg {
        margin-left: 5px;
    }
}

@mixin form-header {
    &__content {
        padding: 10px 0 30px;
        display: flex;
        align-items: center;
    }

    &__logo {
        width: 36px;
        height: 36px;
        margin-right: 11px;
        font-size: 1.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@mixin form-header-with-toggle {
    @include form-header;

    &__toggle {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    .toggle {
        @include no-outline-focus;

        &__label {
            margin-right: 13px;
            margin-bottom: 0;
        }
    }
}

@mixin content-editor-popover-form {
    max-width: $knowledge-base-popover-width;
    width: $knowledge-base-popover-width;
    font-size: $knowledge-base-form-font-size;

    :global(.arrow) {
        display: none !important;
    }
}

@mixin input-info-icon {
    margin-left: 0.5rem;
    color: $ct-gray-500;
}

@mixin disable-default-button-styles {
    padding: 0;
    border: none;
    background: none;
    width: 100%;
}

@mixin scrollable-three-row-title {
    max-height: 65px;
    overflow-y: auto;
    overflow-x: hidden;
}