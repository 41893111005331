@import "../../styles/common";

.webchat-form {
  &__block-message, &__promo-text, &__welcome-message-text, &__debug {
    textarea {
      min-height: 90px;
    }
  }
  &__welcome-message-operator {
    textarea {
      height: calc(1.5em + 0.75rem + 2px)
    }
  }

  &__custom-css {
    textarea {
      min-height: 160px;
    }
  }

  .messages-number-input {
    position: relative;
    width: 90px;
  }

  .input-group-text {
    background-color: transparent;
  }

  &__toggle-field {
    margin-bottom: 1.5rem;
    padding-left: 3px;
    display: flex;
    align-items: center;

    .toggle-field {
      &__label {
        margin-left: 8px;
        margin-bottom: 0;
      }
    }
  }

  &__accordion-with-slider {
    overflow-anchor: auto;
  }

  .horizontal-left-input {
    .form-group {
      margin-bottom: 0;
    }

    .form-label {
      padding-left: 0;
    }
  }

  .promo-number-control {
    padding: 0.375rem 0.65rem;
  }

  &__color-input-group {
    flex: auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    margin-left: -$input-border-width;

    .form-control {
      @include border-left-radius(0);
    }
  }

  &__chat-announcement {
    textarea {
      min-height: 100px;
    }
  }
  
  .captcha-count {
    max-width: 20%;
  }
}
