@use "../../styles/common";

$header-height: 50px;
$settings-size: 56px;

@mixin full-height {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.sidebarRight {
  display: flex;
  flex-direction: column;
  padding: common.$sidebar-vertical-space 0;
  box-shadow: common.$page-box-shadow;
  @include common.transition-all();
  position: fixed;
  overflow: hidden;
  width: common.$sidebar-right-width;
  background-color: common.$body-bg;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2000;
  $self: &;

  &_closed {
    margin-right: -1 * common.$sidebar-right-width;
  }

  &_attached {
    width: 100%;
    position: relative;
    box-shadow: none;
    #{$self}_closed {
      margin-right: auto;
    }
  }

  &__header {
    font-size: common.$page-title-font-size;
    margin-bottom: 10px;
    min-height: $header-height;
    padding: 0 10px 0 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    #{$self} {
      &__backIcon {
        padding: 0;
        width: 25px;
      }
    }

    :global(.btn-close) {
      width: 25px;
    }
  }

  &__title {
    padding-left: 20px;

    &_adjusted {
      margin-right: $settings-size;
    }
  }

  &__settings {
    font-size: common.$font-size-base;
    margin-top: 5px;
    margin-left: auto;
  }

  &__closeBtnLeft, &__closeBtnRight {
    padding: 0;
  }

  &__closeBtnRight {
    margin-left: auto;
    margin-right: 14px;
  }

  &__content {
    @include full-height();
    overflow-x: auto;

    :global(.tab__content) {
      @include full-height();
    }

    :global(.tab__content) {
      > :global(div.active) {
        @include full-height();
      }
    }
  }
}