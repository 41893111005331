@import "../../styles/common";

$add-project-name-width: 160px;
$project-selector-placeholder-color: $ct-gray-600;

.project-selector {
    margin-bottom: $menu-item-bottom-spacing;
    &__project {
        @include interactive-menu-item;
    }
    &__selected-container {
        @include disable-default-button-styles;
    }
    &__selected {
        &_wide-name {
            @include no-wrap-text();
            .project-card__name {
                align-self: flex-start;
            }
        }
    }
    &__dropdown-icon {
        position: absolute;
        right: 7px;
        top: 7px;
        color: $gray-500;
        svg {
            height: 8px;
        }
    }
    &__divider {
        height: 1px;
        background-color: $gray-400;
        margin: {
            top: 7px;
            bottom: 7px;
            left: $sidebar-item-left-padding;
            right: $sidebar-item-left-padding;
        };
    }
    &__wizard-redirect {
        @include interactive-menu-item;
        margin: 0 7px;

        .wizard-redirect-button__icon {
            margin-right: 10px;
        }
    }

    &__context-menu {
        width: 260px;
    }

    &__projects {
        @include mini-scrollbar;
        max-height: 400px;
        overflow-y: auto;
        padding: 0 7px;

        &:last-child {
            margin-bottom: 10px;
        }
    }

    &__search {
        position: relative;
        margin-bottom: 10px;
        padding: 8px 12px;
    }

    &__search-icon {
        position: absolute;
        pointer-events: none;
        left: 26px;
        top: 19px;
        color: $ct-gray-650;
    }

    &__search-input {
        padding-left: 38px;
        &::placeholder {
            color: $project-selector-placeholder-color;
        }
    }
}