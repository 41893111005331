@use "../../../../../styles/common";
@import "../../../../../styles/common";

$keyboard-settings-button-size: 0.9 * $ct-font-size-base;

.keyboard {
  font-size: $knowledge-base-article-text-font-size;
  margin: 15px 0;

  &__add-btn-popover {
    &:global(.popover) {
      @include common.content-editor-popover-form;
    }
  }

  &__add-icon-container {
    @include transition();
    opacity: 0;
    pointer-events: none;
    padding: 2px 8px;
    display: inline-block;
  }

  &__add-icon {
    padding: 0;
    font-size: 14px;
  }

  &__buttons {
    display: flex;
  }

  &__button-rows {
    flex: 1;
  }

  &__button-row {
    display: flex;
    margin-bottom: 8px;
  }

  &__settings-btn {
    @include transition();
    padding-left: 10px;
    padding-top: 2px;
    opacity: 0;
    pointer-events: none;
    display: flex;

    &:before {
      position: absolute;
      background-color: #F2F2F2;
      border-radius: 5px;
      width: 24px;
      height: 24px;
    }
  }

  &__settings-icon {
    padding: 0;
    color: $knowledge-base-sub-text;
    font-size: $keyboard-settings-button-size;
    margin-left: 10px;

    &:first-of-type {
      margin-left: 0;
    }
  }

  &:not(.keyboard_disabled) {
    &:hover {
      .keyboard {
        &__settings-btn, &__add-icon-container {
          opacity: 1;
          pointer-events: auto;
          cursor: pointer;
        }
      }
    }
  }
}
