// use this style sheet for bootstrap class overrides

// buttons
.btn-light {
  color: $primary;

  &:not(:disabled):hover {
    color: $primary;
  }
}

.btn-link {
  color: $gray-500;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    color: $body-color;
    text-decoration: none;
    box-shadow: none;
  }

  &:not(:disabled):hover {
    color: $primary;
  }
}

.btn-outline-light {
  color: $ct-gray-600;
  border-color: $ct-gray-300;

  &:hover {
    color: $primary;
    background-color: $ct-gray-300;
    border-color: $ct-gray-300;
  }

  &:disabled, &.disabled {
    color: $ct-gray-300;
    background-color: transparent;
  }
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: $primary;
  background-color: $ct-gray-300;
  border-color: $ct-gray-300;
}

.btn-light-danger {
  @include button-variant($ct-red-6, $ct-red-6, $ct-red-6);
  color: $ct-red-4;

  &:hover {
    color: $ct-red-4;
  }

  &:focus,
  &.focus {
    color: $ct-red-4;
  }

  &:disabled,
  &.disabled {
    color: $ct-red-4;
    pointer-events: none;
  }
}

.btn-light-danger:not(:disabled):not(.disabled):active,
.btn-light-danger:not(:disabled):not(.disabled).active,
.show > .btn-light-danger.dropdown-toggle {
  color: $ct-red-4;
}

.modal-backdrop {
  z-index: 5040;
}

.modal.fade {
  z-index: 5050;
}

input, button, select, optgroup, textarea {
  color: inherit;
}