@use "../../styles/common";

$menu-item-icon-size: 1.8rem;

.menuItem {
    @include common.disable-default-button-styles;
    margin-bottom: common.$menu-item-bottom-spacing;
    $self: &;
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: common.$transition-base;
        border-radius: common.$border-radius;
        user-select: none;
        padding: 5px 2px;
    }
    &__title {
        font: {
            size: common.$menu-item-title-font-size;
        }
        text-align: center;
        line-height: 1.4;
    }
    &__menuIcon {
        width: $menu-item-icon-size;
        height: $menu-item-icon-size;
        font-size: common.$sidebar-menu-icon-size;
    }
    &:hover {
        #{$self}__container {
            background-color: common.$sidebar-hover-color;
        }
    }
    &_selected {
        color: common.$sidebar-selected-color;
    }
}