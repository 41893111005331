@use "../../styles/common";

$actions-font-size: common.$font-size-base;
$actions-border-color: common.$gray-400;

.actionInput {
  &__control {
    border-right: none;
    &_empty {
      border-right: 1px solid common.$input-border-color;
      border-radius: common.$input-border-radius;
    }
  }
  &__actions {
    @include common.transition-all();
    border-radius: 0 common.$input-border-radius common.$input-border-radius 0;
    border: 1px solid $actions-border-color;
    border-left: none;
    :global(.btn) {
      font-size: $actions-font-size;
      padding: common.$input-padding-y * .85 common.$input-padding-x * .85;
      &:disabled {
        background-color: common.$input-disabled-bg;
        color: common.$body-color;
      }
    }
  }
}