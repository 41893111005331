.messageEndDialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #a2a2a2;
    margin-top: 30px;
    margin-bottom: 30px;

    &__id {
        color: #a2a2a2;
        margin-bottom: 11px;
    }

    &__text {
        margin-bottom: 8px;
    }
}
