.dropdown {
    &__toggle {
        padding: 0;
        margin: 0;
        background: transparent !important;
        box-shadow: none !important;
        border: none 0;
        outline: none;
        display: flex;
        line-height: normal;
        font-size: inherit;

        &:after {
            display: none;
        }

        &:hover {
            background: transparent;
        }
    }

    &__list {
        border-radius: 5px;
        background: #fff;
        border: none 0;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
    }

    &__item {
        padding: 7px 16px;
        background: transparent;
        transition: all 300ms ease;

        &:hover {
            background: #f2f2f2;
        }
    }

    &__toggleButton {
        background: none;
        margin: 0;
        border: none;
    }
}
