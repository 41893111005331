@use "../../styles/common" as common;

$bg-color-btn: common.$body-bg;
$border-color-btn: common.$input-border-color;

.inputWithDropMenuAndToggle {
  &__inputSelect {
    width: 100%;
    min-width: 120px;
    :global(.custom-select__control) {
        border-radius: 0 common.$border-radius common.$border-radius 0;
    }
  }
  &__wrap {
    flex-wrap: nowrap;
  }

 &__dropdownIcon{
    margin-right: 11px;
    color: common.$knowledge-base-sub-text;
  }
}