@import "../../styles/common";

$radio-input-color: $primary;

.radio-input {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  input, label {
    cursor: pointer;
  }

  input[type="radio"]:checked+label {
    &::before {
      border: 1px solid $radio-input-color;
      background-color: $body-bg;
    }

    &::after {
      transform: scale(0.7);
      border-radius: 50%;
      background-color: $radio-input-color;
      background-image: none !important;
    }
  }
}