@use "../../styles/common" as common;

$react-toggle-border-inactive: 1px solid common.$ct-gray-650;
.react-toggle {
        font-size: 10px;
        &:hover:not(.react-toggle--disabled):not(.increaseSpecificity) .react-toggle-track {
            background-color: common.$white;
        }

        .react-toggle-track:not(.increaseSpecificity) {
            height: 1.8em;
            width: 2.8em;
            border: $react-toggle-border-inactive;
            background-color: common.$white;
        }

        .react-toggle-thumb:not(.increaseSpecificity) {
            height: 1.2em;
            width: 1.2em;
            top: 0.3em;
            left: 0.3em;
            border: $react-toggle-border-inactive;
            background-color: common.$white;
        }
        &--checked:not(.increaseSpecificity) {
            .react-toggle-track {
                border: 1px solid common.$primary;
                background-color: common.$white;
            }
            .react-toggle-thumb {
                border: none;
                left: 1.3em;
                background-color: common.$primary;
            }

            &:hover:not(.react-toggle--disabled) .react-toggle-track {
                background-color: common.$white;
            }
        }
        &_small:not(.increaseSpecificity) {
            .react-toggle-track {
                height: 16px;
                width: 26px;
            }

            .react-toggle-thumb {
                height: 10px;
                width: 10px;
                top: 3px;
            }

            .react-toggle--checked {
                .react-toggle-thumb {
                    left: 12px;
                }
            }
        }
}