@use "../../styles/common";

.passwordControl {
  position: relative;

  &__input {
    :global(.form-control.is-invalid) {
      background-image: none;
    }
  }

  &__iconContainer {
    position: absolute;
    top: 2.5rem;
    right: 0.75rem;
    display: flex;
    width: 1.3rem;
    justify-content: center;
  }

  &__icon {
    @include common.transition(color .3s);
    font-size: 15px;
    cursor: pointer;
    color: common.$text-muted;

    &:hover {
      color: common.$primary;
    }
  }

  &__withError {
    right: 2rem;
  }
}