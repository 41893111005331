@use "../../styles/common";

$page-layout-left-sidebar-max-width: 450px;
$page-layout-left-sidebar-min-width: common.$sidebar-width;
$page-layout-width: common.$sidebar-right-width;
$page-layout-left-sidebar-title-color: common.$ct-black-50;

.pageLayout {
    display: flex;
}

.pageLayoutContent {
    flex: 1;
    height: 100vh;
    @include common.transition-all();
    overflow-y: auto;

    &_hidden {
        display: none;
    }

    &_full {
        margin-right: common.$sidebar-right-width;
    }
}

.pageLayoutLeftSidebar {
    @include common.flex-column;
    height: 100vh;
    max-width: $page-layout-left-sidebar-max-width;
    min-width: $page-layout-left-sidebar-min-width;
    overflow: hidden;
    border-right: common.$sidebar-divider;
    @include common.transition-all();

    &_collapsible {
        width: 100%;
        max-width: none;
        min-width: auto;
        overflow: initial;
    }

    &_hidden {
        display: none;
    }

    &_closed {
        margin-left: -1 * $page-layout-left-sidebar-max-width + 70;
    }

    &__title {
        margin-right: auto;
        color: $page-layout-left-sidebar-title-color;
        font-size: 24px;
    }

    &__actions {
        > *:not(:last-child) {
            margin-right: 10px;
        }
    }

    &__intermediate {
    }

    &__header {
        display: flex;
        align-items: center;
        padding: 40px 15px 30px;
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        overflow-y: auto;

        &_notScrollable {
            overflow-y: hidden;
        }
    }

    &__collapse {
        &:global(.btn) {
            position: relative;
            width: 24px;
            height: 38px;
            top: 40px;
            background: #f2f2f2;
            border-radius: 0 5px 5px 0;
            border: none;
            display: flex;
            justify-content: center;
            align-content: center;
        }

        &__collapseIcon {
            font-size: common.$font-size-base * 1.5;
            margin-top: 2px;
            margin-left: -4px;
        }
    }
}

.pageLayoutSidebar {
    &_extended {
        width: 670px;
    }
}
