@use "../../styles/common";

.dialogTime {
    display: flex;
    align-items: center;

    &__icon {
        margin-right: 10px;
    }

    &__timeSpan {
        font-size: 1.125rem;
        color: #333;
    }
}
