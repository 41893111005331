
//sidebar
$sidebar-item-left-padding: 10px;
$sidebar-hover: $gray-200;
$sidebar-opened-width: 310px;
$sidebar-width: 90px;
$sidebar-vertical-padding: 30px;
$sidebar-menu-icon-size: 1.5rem;
$sidebar-menu-row-icon-size: 1.3rem;

// sidebar-right
$sidebar-right-width: 450px;

//public form
$public-form-default-width: 450px;
$public-form-h1-size: $ct-font-size-base * 3.5;
$public-form-h1-weight: 400;
$public-form-h2-size: $ct-font-size-base * 1.3;
$public-form-h2-weight: 300;

// text properties
$sub-text-size: $ct-font-size-base * .75;

//page
$page-title-font-size: $ct-font-size-base * 1.5;

//menu
$menu-item-title-font-size: $ct-font-size-base * .75;
$menu-item-bottom-spacing: 10px;

//queue
$board-min-height: 112px;

//spinner
$loading-spinner-size: $font-size-base * 1.1;

//knowledgeBase
$knowledge-base-intent-title-font-size: $ct-font-size-base * 1.86;
$knowledge-base-intent-text-font-size: $ct-font-size-base * 1.29;
//$knowledge-base-article-title-font-size: $ct-font-size-base * 1.86;
$knowledge-base-article-title-font-size: $ct-font-size-base * 2.14;
$knowledge-base-article-text-font-size: $ct-font-size-base * 1.29;
$knowledge-base-form-font-size: $ct-font-size-base;
$knowledge-base-popover-width: 450px;
$article-title-height: 40px;
$article-view-left-indent: $sidebar-width + 380px;

//scenario
$scenario-edge-width: 2px;
$scenario-node-handle-size: 12px;
$scenario-node-target-handle-size: 40px;
$form-node-width: 350px;

//wizard
$wizard-sidebar-width: 280px;

//font-size
$font-size-12: $font-size-base * .875;
$font-size-16: $font-size-base * 1.14;
$font-size-18: $font-size-base * 1.29;
