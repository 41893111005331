@use "../../styles/common";

.zoomImagePan {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__controls {
    float: right;
  }

  &__control {
    margin-left: 12px;
  }

  img, .zoomImagePan__wrapper {
    width: 100%;
    height: 100%;
  }
}