@use "../../styles/common" as common;

$message-node-buttons-list-item-height: 32px;

.initialButtonsEditorAddButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__icon{
    margin-right: 11px;
  }
}
.initialButtonsList {
  padding-top: 25px;
}