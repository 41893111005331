.questionerSlot {
    &__section {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__content {
        flex-grow: 1;
        display: flex;
        align-items: center;
        min-height: 35px;
        margin-left: 10px;
        cursor: pointer;
    }

    &__title {
        flex: 0 0 30%;

        &::after {
            content: ":";
        }
    }

    &__actionsContainer {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    &__actionsButton {
        background: none;
        border: none;

        svg {
            margin-left: 2px;
        }

        path {
            fill: #a2a2a2;
        }

        &:hover {
            path {
                fill: #1066a8;
            }
        }
    }

    &__dictionaryActionsContainer {
        right: 18px;
    }
}
